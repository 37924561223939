import React, { useState } from "react";
import styles from "./Shop.module.scss";

//components

import Product from "../../components/Product/Product";
import Footer from "../../components/Footer/Footer";
import Navbar from "../../components/Navbar/Navbar";

//images
import PowerBiForDummies from "../../images/power-bi-for-dummies.jpg";
import JavaVirtualMachineSpecification from "../../images/java-virtual-machine-spec.webp";
import Pmp from "../../images/pmp.jpg";
import StartProgramming from "../../images/start-programming.jpg";
import ComputerScience from "../../images/computer-science.webp";
import DatabaseProjects from "../../images/database-projects.jpg";
import SEO from "../../images/seo.webp";
import SystemDesign from "../../images/system-design.jpg";
import SQLServer from "../../images/sql-server.jpg";
import SearchIcon from "../../images/search.svg";

const Shop = ({ showCart, setShowCart, cartItems, setCartItems }) => {
  const [searchResult, setSearchResult] = useState([]);
  const items = [
    {
      name: "Microsoft Power Bi For Dummies",
      image: PowerBiForDummies,
      price: 22.3,
      old_price: 25.3,
      id: 1,
    },
    {
      name: "The Java� Virtual Machine Specification (Java Series)",
      image: JavaVirtualMachineSpecification,
      price: 2.7,
      old_price: 26.9,
      id: 2,
    },

    {
      name: "PMP: Project Management Professional Exam Review Guide",
      image: Pmp,
      price: 16,
      old_price: 22,
      id: 3,
    },
    {
      name: "Start Programming Using HTML, CSS, and JavaScript",
      image: StartProgramming,
      price: 139,
      old_price: 168,
      id: 4,
    },
    {
      name: "Computer Science",
      image: ComputerScience,
      price: 6.7,
      old_price: 65,
      id: 5,
    },
    {
      name: "Database Projects in Access for Advanced Level 2nd Edition",
      image: DatabaseProjects,
      price: 3.9,
      old_price: 11.9,
      id: 6,
    },
    {
      name: "Search Engine Optimization: An Hour a Day",
      image: SEO,
      price: 3.9,
      old_price: 19,
      id: 7,
    },
    {
      name: "System Analysis and Design: Technology & Applications",
      image: SystemDesign,
      price: 37.9,
      old_price: 37.9,
      id: 8,
    },
    {
      name: "SQL Server 2019 Revealed: Including Big Data Clusters and Machine Learning",
      image: SQLServer,
      price: 37.1,
      old_price: 37.1,
      id: 9,
    },
  ];

  const handleSearch = (term) => {
    const _items = items?.filter((item) =>
      item?.name?.toLowerCase()?.includes(term?.toLowerCase())
    );
    if (_items) {
      setSearchResult(_items);
    }
  };

  return (
    <div className={styles.shop}>
      <div className={styles.shop__main}>
        <div className={styles.shop__main__heading}>
          <h4>Home / Shop</h4>
          <h2>Shop</h2>
        </div>
        {/* <h3 className={styles.shop__main__heading}>Clothing</h3> */}
        <div className={styles.shop__main__content}>
          <div className={styles.shop__main__search}>
            <img src={SearchIcon} alt="search" />
            <input
              onChange={(e) => handleSearch(e.target.value)}
              type="text"
              placeholder="Search products..."
            />
          </div>

          <div className={styles.shop__main__products}>
            {searchResult && searchResult?.length > 0
              ? searchResult?.map((item) => (
                  <Product
                    key={item?.name}
                    item={item}
                    cartItems={cartItems}
                    setCartItems={setCartItems}
                  />
                ))
              : items?.map((item) => (
                  <Product
                    key={item?.name}
                    item={item}
                    cartItems={cartItems}
                    setCartItems={setCartItems}
                  />
                ))}
          </div>

          <div className={styles.shop__main__buttons}>
            <button>Back</button>
            <button>Next</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Shop;
