import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { List, Bag } from "phosphor-react";
import styles from "./Navbar.module.scss";

//icons
import X from "../../images/x.svg";
import Logo from "../../images/logo.svg";

const Navbar = ({ active, cartItems, showCart, setShowCart, setCartItems }) => {
  const navigate = useNavigate();
  const [showNav, setShowNav] = useState(false);
  const [totalItems, setTotalItems] = useState(0);

  useEffect(() => {
    const t =
      cartItems?.length > 0
        ? cartItems.reduce((total, value) => total + 1 * value?.count, 0)
        : 0;
    setTotalItems(t);
  }, [cartItems]);
  return (
    <div className={styles.navBar__box}>
      <div className={styles.navBar}>
        <img
          onClick={() => navigate("/")}
          alt="logo"
          src={Logo}
          className={styles.navBar__logo}
        />

        <div className={styles.navBar__items}>
          <div
            onClick={() => navigate("/")}
            className={
              active === "Home"
                ? `${styles.navBar__item} ${styles.navBar__item__active}`
                : `${styles.navBar__item}`
            }
          >
            Home
          </div>
          <div
            onClick={() => navigate("/shop")}
            className={
              active === "Shop"
                ? `${styles.navBar__item} ${styles.navBar__item__active}`
                : `${styles.navBar__item}`
            }
          >
            Shop
          </div>
          <div
            onClick={() => navigate("/enterprise-solutions")}
            className={
              active === "EnterpriseSolutions"
                ? `${styles.navBar__item} ${styles.navBar__item__active}`
                : `${styles.navBar__item}`
            }
          >
            Enterprise Solutions
          </div>
          <div
            onClick={() => navigate("/about-us")}
            className={
              active === "AboutUs"
                ? `${styles.navBar__item} ${styles.navBar__item__active}`
                : `${styles.navBar__item}`
            }
          >
            About Us
          </div>
          <div
            onClick={() => navigate("/contact-us")}
            className={
              active === "ContactUs"
                ? `${styles.navBar__item} ${styles.navBar__item__active}`
                : `${styles.navBar__item}`
            }
          >
            Contact Us
          </div>
        </div>
        <div onClick={() => setShowNav(true)} className={styles.navBar__menu}>
          <List size={24} color="#686868" weight="regular" />
        </div>
        <div
          className={styles.navBar__cart}
          onClick={() => setShowCart(!showCart)}
        >
          <Bag weight="regular" color="#333333" size={32} />
          {totalItems > 0 && <h3>{totalItems}</h3>}
        </div>
      </div>

      {showNav && (
        <div className={styles.navBar__mobile}>
          <div
            onClick={() => setShowNav(false)}
            className={styles.navBar__mobile__close}
          >
            <img src={X} alt="close" />
          </div>
          <div className={styles.navBar__mobile__items}>
            <div
              onClick={() => {
                navigate("/");
                setShowNav(false);
              }}
              className={
                active === "Home"
                  ? `${styles.navBar__mobile__item} ${styles.navBar__mobile__item__active}`
                  : `${styles.navBar__mobile__item}`
              }
            >
              Home
            </div>
            <div
              onClick={() => {
                navigate("/shop");
                setShowNav(false);
              }}
              className={
                active === "Shop"
                  ? `${styles.navBar__mobile__item} ${styles.navBar__mobile__item__active}`
                  : `${styles.navBar__mobile__item}`
              }
            >
              Shop
            </div>
            <div
              onClick={() => {
                navigate("/enterprise-solutions");
                setShowNav(false);
              }}
              className={
                active === "EnterpriseSolutions"
                  ? `${styles.navBar__mobile__item} ${styles.navBar__mobile__item__active}`
                  : `${styles.navBar__mobile__item}`
              }
            >
              Enterprise Solutions
            </div>
            <div
              onClick={() => {
                navigate("/about-us");
                setShowNav(false);
              }}
              className={
                active === "AboutUs"
                  ? `${styles.navBar__mobile__item} ${styles.navBar__mobile__item__active}`
                  : `${styles.navBar__mobile__item}`
              }
            >
              About Us
            </div>
            <div
              onClick={() => {
                navigate("/contact-us");
                setShowNav(false);
              }}
              className={
                active === "ContactUs"
                  ? `${styles.navBar__mobile__item} ${styles.navBar__mobile__item__active}`
                  : `${styles.navBar__mobile__item}`
              }
            >
              Contact Us
            </div>
            {/* <div className={styles.navBar__mobile__item}>
              <div className={styles.navBar__mobile__logout}>
                <SignOut size={20} />
                <span>Sign Out</span>
              </div>
            </div> */}
          </div>
        </div>
      )}
    </div>
  );
};

export default Navbar;
