import React, { useState } from "react";
import styles from "./Home.module.scss";
import { useNavigate } from "react-router-dom";
import { CurrencyCircleDollar } from "phosphor-react";
//images
import IOT from "../../images/iot.jpeg";
import AI from "../../images/ai.jpeg";
import Salesforce from "../../images/salesforce.jpeg";
import Software from "../../images/software.jpeg";

import { courses } from "../../utils/courses";

const Home = () => {
  const navigate = useNavigate();

  return (
    <div className={styles.home}>
      {/* <Navbar /> */}
      <div className={styles.home__intro}>
        <div className={styles.home__intro__main}>
          <h2>Blaaiz</h2>
          <p>ELECTRONIC RESOURCES FOR YOUR LEARNING NEEDS</p>
          <button onClick={() => navigate("/shop")}>Shop Now</button>
        </div>
      </div>
      <div className={styles.home__cards}>
        {courses?.map((course) => (
          <div className={styles.home__cards__item}>
            <img src={course?.image} alt="iot" />
            <div className={styles.home__cards__item__text}>
              <h3>{course?.title}</h3>
              <h5>
                <CurrencyCircleDollar size={22} color="#333333" />{" "}
                {course?.price?.toLocaleString() || 0}
                <span>
                  {" "}
                  <CurrencyCircleDollar size={22} color="#b3b3b3" />
                  {course?.old_price?.toLocaleString() || 0}
                </span>
              </h5>
              <p>{course?.desc}</p>
              <h6>DURATION: {course?.duration}</h6>
              <button onClick={() => navigate(`/product/${course?.id}`)}>
                ENROL
              </button>
            </div>
          </div>
        ))}

        <button className={styles.home__cards__more}>view more...</button>
      </div>
    </div>
  );
};

export default Home;
