import React from "react";
import styles from "./Solutions.module.scss";

//components
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";

//images
import Innovation from "../../images/innovation.png";

const Solutions = () => {
  return (
    <div className={styles.solutions}>
      <div className={styles.solutions__main}>
        <div className={styles.solutions__main__heading}>
          <h4>Home / Enterprise Solutions</h4>
          <h2>Enterprise Solutions</h2>
        </div>
        <div className={styles.solutions__main__solutions}>
          <img
            src={Innovation}
            className={styles.solutions__main__image}
            alt="innovation"
          />

          <div className={styles.solutions__main__solutions__items}>
            <div className={styles.solutions__main__solutions__item}>
              <h4>Software Solutions</h4>
              <p>
                We have a track record of designing, implementing and monitoring
                enterprise training for companies staff and vendors
              </p>
              <p>
                Our solutions are mainly designed for HR, and compliance
                functions who require baseline levels of awareness for their
                staff.
              </p>
            </div>

            <div className={styles.solutions__main__solutions__item}>
              <h4>Hardware / IT Supply</h4>
              <p>
                We have relèvent partnerships in key markets for the supply of
                IT tools and equipments, including laptops, servers, And
                peripheral devices such as keyboards, mouse, printers, scanners,
                Etc
              </p>
              <p>
                We also offer upgrade for tools that were purchased via us at
                Fair prices. Think out IT hardware and upgrade as-a-service
              </p>
            </div>
          </div>
        </div>
        <div className={styles.solutions__weUnderstand}>
          <p>
            We understand that different firms have different needs, our team is
            able to advice you on tailoring your needs for process efficiency,
            reduced cost, regulatory compliance, and embedding the right
            organisational culture.
          </p>
          <h4>Please contact us on hello@blaaiz.com</h4>
        </div>
      </div>

    </div>
  );
};

export default Solutions;
