import React, { useState, useEffect } from "react";
import { Backspace, CurrencyCircleDollar } from "phosphor-react";
import styles from "./CartItem.module.scss";



const CartItem = ({ item, cartItems, setCartItems }) => {
  const [count, setCount] = useState(item?.count ?? 1);

  const removeItem = () => {
    const newCartItems = cartItems.filter(
      (cartItem) => cartItem?.id !== item?.id
    );

    setCartItems(newCartItems);
  };

  useEffect(() => {
    const oldItems = cartItems;
    let objIndex = oldItems.findIndex((obj) => obj?.id == item?.id);

    oldItems[objIndex].count = count;
    

    setCartItems([...oldItems]);
  }, [count]);




  return (
    <div className={styles.cartItem}>
      <div className={styles.cartItem__image}>
        <img src={item?.image} alt="clothing" />
      </div>
      <div className={styles.cartItem__content}>
        <div className={styles.cartItem__content__row}>
          <h4>{item?.name}</h4>
          <div
            onClick={removeItem}
            className={styles.cartItem__content__cancel}
          >
            <Backspace size={25} color="#333" />
          </div>
        </div>

        <div className={styles.cartItem__content__row}>
          <div className={styles.cartItem__content__quantity}>
            <div
              onClick={() => (count >= 2 ? setCount(count - 1) : null)}
              style={count === 1 ? { color: "#e6e6e6" } : {}}
              className={styles.cartItem__content__quantity__decrease}
            >
              -
            </div>
            <div className={styles.cartItem__content__quantity__value}>
              {count}
            </div>
            <div
              onClick={() => setCount(count + 1)}
              className={styles.cartItem__content__quantity__increase}
            >
              +
            </div>
          </div>
          <div className={styles.cartItem__content__price}>
            <CurrencyCircleDollar size={22} color="#333333" />
            {item?.price}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CartItem;
