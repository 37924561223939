import React from "react";
import styles from "./ContactUs.module.scss";

//components
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";

//images
import Innovation from "../../images/innovation.png";

const ContactUs = () => {
  return (
    <div className={styles.contactUs}>
      {/* <Navbar /> */}
      <div className={styles.contactUs__main}>
        <div className={styles.contactUs__main__heading}>
          <h4>Home / Contact Us</h4>
          <h2>Contact Us</h2>
        </div>
        <div className={styles.contactUs__main__contact}>
          <h3>Contact us at hello@blaaiz.com</h3>
          <p>
            P O BOX 2032, city square <br />
            Riverside Square Kofisi <br />
            Dogoreti Ditsrict <br />
            Nairobi
          </p>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
