import React from "react";
import { Route, Routes as Switch, Navigate } from "react-router-dom";

import Guard from "./Guard";
import Layout from "../layout/Layout";

// pages
import Home from "../pages/Home/Home";
import AboutUs from "../pages/AboutUs/AboutUs";
import ContactUs from "../pages/ContactUs/ContactUs";
import Shop from "../pages/Shop/Shop";
import Solutions from "../pages/Solutions/Solutions";
import ProductDetails from "../pages/ProductDetails/ProductDetails";

const Routes = () => {
  const routeItems = [
    {
      path: "/",
      name: "Home",
      component: Home,
      guard: false,
    },
    {
      path: "/about-us",
      name: "AboutUs",
      component: AboutUs,
      guard: false,
    },
    {
      path: "/contact-us",
      name: "ContactUs",
      component: ContactUs,
      guard: false,
    },
    {
      path: "/shop",
      name: "Shop",
      component: Shop,
      guard: false,
    },
    {
      path: "/enterprise-solutions",
      name: "EnterpriseSolutions",
      component: Solutions,
      guard: false,
    },
    {
      path: `/product/:id`,
      name: "Home",
      component: ProductDetails,
      guard: false,
    },
  ];
  return (
    <>
      <Switch>
        {routeItems.map((route, index) => {
          return (
            <Route
              path={route?.path}
              key={index}
              element={
                route.guard ? (
                  <Guard>
                    <Layout name={route?.name}>
                      <route.component />
                    </Layout>
                  </Guard>
                ) : (
                  <Layout name={route?.name}>
                    <route.component />
                  </Layout>
                )
              }
            />
          );
        })}
        <Route path="*" element={<Navigate to="/" replace />} />
      </Switch>
    </>
  );
};

export default Routes;
