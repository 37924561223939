import React from "react";
import styles from "./AboutUs.module.scss";

//components
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";

//images
import Innovation from "../../images/innovation.png";

const AboutUs = () => {
  return (
    <div className={styles.aboutUs}>
      <div className={styles.aboutUs__main}>
        <div className={styles.aboutUs__main__heading}>
          <h4>Home / About Us</h4>
          <h2>About Us</h2>
        </div>
        <div className={styles.aboutUs__main__intro}>
          <h3>Blaaiz Innovations</h3>
          <p>
            At blaaiz, We are not just an
            edtech/IT vendor; we are your partners in learning and
            mordernisation. Whether you are looking to acquire new skills,
            enhance your career prospects, embark on a learning adventure, or
            looking to constantly stay ahead of the market in terms of your IT
            tooling and infrastructure, we invite you to join us on this
            exciting journey.
          </p>
        </div>

        <img
          src={Innovation}
          className={styles.aboutUs__main__image}
          alt="innovation"
        />

        <div className={styles.aboutUs__main__visions}>
          <div className={styles.aboutUs__main__vision}>
            <h4>Our Vision</h4>
            <p>
              To create a world where learning never stops. Where individuals of
              all backgrounds and ages can access quality education, explore
              their passions, and embrace lifelong learning as a way of life. We
              envision a future where knowledge knows no limits and empowers
              individuals to thrive in an ever-changing world
            </p>
            <p>
              To enable organisations in their pursuit of cultivating a culture
              of learning while ensuring that employees are able to access the
              most updated IT tools and equipments per time for optimal
              performance and shared success
            </p>
          </div>

          <div className={styles.aboutUs__main__vision}>
            <h4>Our Mission</h4>
            <p>
              To empower learners worldwide by providing a dynamic platform
              where education knows no boundaries. We’re committed to fostering
              a learning environment that’s not only informative but also
              inspiring. Through our diverse range of expertly crafted courses,
              we aim to ignite curiosity, spark creativity, and fuel your quest
              for knowledge.
            </p>
            <p>
              To empower organisations to scale their IT infrastructure fast and
              cheaply give the ever-evolving invention that results IT tools and
              equipments Being unoptimised for new-age challenges and problems
              while doing our best in recycling IT equipments via our
              partnerships with manufacturers.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
