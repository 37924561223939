import React from "react";
import { useNavigate } from "react-router-dom";
import styles from "./Footer.module.scss";

//images
import Logo from "../../images/logo.svg";

const Footer = ({ setShowCart }) => {
  const navigate = useNavigate();
  return (
    <div className={styles.footer__box}>
      <div className={styles.footer}>
        <div className={styles.footer__main}>
          <img
            onClick={() => navigate("/")}
            className={styles.footer__logo}
            src={Logo}
            alt="logo"
          />

          <div className={styles.footer__links}>
            <h3>Quick Links</h3>
            <p onClick={() => navigate("/about-us")}>About Us</p>
            <p onClick={() => navigate("/contact-us")}>Contact Us</p>
            <p onClick={() => setShowCart(true)}>Cart</p>
          </div>

          <div className={styles.footer__desc}>
            <h3>At Blaaiz</h3>
            <p>
              We are not just an edtech/IT vendor; we are your partners in
              learning and mordernisation. Whether you are looking to acquire
              new skills, enhance your career prospects, embark on a learning
              adventure, or looking to constantly stay ahead of the market in
              terms of your IT tooling and infrastructure, we invite you to join
              us on this exciting journey.
            </p>
          </div>
        </div>
        <div className={styles.footer__copy}>
          <p>Copyright © 2023</p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
