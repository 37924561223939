import React, { useState, useEffect } from "react";
import { X, Ghost, CurrencyCircleDollar } from "phosphor-react";

import styles from "./Cart.module.scss";

//components
import CartItem from "../CartItem/CartItem";
import Select from "../Select/Select";

const Cart = ({
  setShowCart,
  cartItems,
  setCartItems,
  firstName,
  setFirstName,
  lastName,
  setLastName,
  email,
  setEmail,
  phoneNumber,
  setPhoneNumber,
}) => {
  const cardNumbers = [
    { label: "5555555555554444", value: "5555555555554444" },
    { label: "5105105105105100", value: "5105105105105100" },
    { label: "40128888888818813", value: "40128888888818813" },
  ];

  const states = [
    { label: "New York", value: "New York" },
    { label: "California", value: "California" },
    { label: "Florida", value: "Florida" },
  ];

  const [total, setTotal] = useState(0);

  const [merchantSet, setMerchantSet] = useState(true);

  const [stateSelected, setStateSelected] = useState();

  const [descSet, setDescSet] = useState(true);
  const [cardSelected, setCardSelected] = useState();

  // const handleSubmit = () => {
  //   console.log(card, merchant);

  //   if (!card?.value) {
  //     setCardSelected(false);
  //     return;
  //   } else {
  //     setCardSelected(true);
  //   }

  //   if (!merchant) {
  //     setMerchantSet(false);
  //     return;
  //   } else {
  //     setMerchantSet(true);
  //   }

  //   if (!state?.value) {
  //     setStateSelected(false);
  //     return;
  //   } else {
  //     setStateSelected(true);
  //   }

  //   if (!description) {
  //     setDescSet(false);
  //     return;
  //   } else {
  //     setDescSet(true);
  //   }

  //   mutate({
  //     card_number: Number(card.value),
  //     merchant_number: merchant,
  //     amount: total,
  //     merchant_state: state.value,
  //     merchant_description: description,
  //   });
  // };

  useEffect(() => {
    const t = cartItems.reduce(
      (total, value) => total + value?.price * value?.count,
      0
    );
    setTotal(t);
  }, [cartItems]);

  return (
    <div className={styles.cart}>
      <div className={styles.cart__main}>
        <div className={styles.cart__main__heading}>
          <h3>Cart</h3>
          <div
            className={styles.cart__main__cancel}
            onClick={() => setShowCart(false)}
          >
            <X size={20} color="#333" />
          </div>
        </div>
        <div className={styles.cart__main__content}>
          {cartItems?.length > 0 ? (
            cartItems?.map((item) => (
              <CartItem
                key={item?.id}
                cartItems={cartItems}
                setCartItems={setCartItems}
                item={item}
              />
            ))
          ) : (
            <div className={styles.cart__main__empty}>
              <div className={styles.cart__main__empty__ghost}>
                <Ghost color="#b3b3b3" size={42} />
              </div>

              <p>Cart is empty</p>
            </div>
          )}
        </div>

        <div className={styles.cart__main__footer}>
          <div className={styles.cart__main__footer__form}>
            <div
              className={
                descSet === true
                  ? `${styles.cart__main__footer__input}`
                  : `${styles.cart__main__footer__input} ${styles.cart__main__footer__input__notFound}`
              }
            >
              <p>First name</p>
              <input
                type="text"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
              />
            </div>
            <div
              className={
                merchantSet === true
                  ? `${styles.cart__main__footer__input}`
                  : `${styles.cart__main__footer__input} ${styles.cart__main__footer__input__notFound}`
              }
            >
              <p>Last name</p>
              <input
                type="tel"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
              />
            </div>
            <div
              className={
                descSet === true
                  ? `${styles.cart__main__footer__input}`
                  : `${styles.cart__main__footer__input} ${styles.cart__main__footer__input__notFound}`
              }
            >
              <p>Email</p>
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div
              className={
                descSet === true
                  ? `${styles.cart__main__footer__input}`
                  : `${styles.cart__main__footer__input} ${styles.cart__main__footer__input__notFound}`
              }
            >
              <p>Phone number</p>
              <input
                type="tel"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
              />
            </div>
            {/* <div className={styles.cart__main__footer__select}>
              <p>Merchant State</p>
              <Select
                selectedOption={state}
                setSelectedOption={setState}
                options={states}
                selected={stateSelected}
              />
            </div> */}
            {/* <div className={styles.cart__main__footer__select}>
              <p>Card Number</p>
              <Select
                selectedOption={card}
                setSelectedOption={setCard}
                options={cardNumbers}
                selected={cardSelected}
              />
            </div> */}

            {/* <div className={styles.cart__main__footer__select}>
              <p>Merchant Name</p>
              <Select
                selectedOption={merchant}
                setSelectedOption={setMerchant}
                options={merchantNames}
                selected={merchantSet}
              />
            </div> */}
          </div>

          <div className={styles.cart__main__footer__checkout}>
            {" "}
            <h4>
              Total:
              <span>
                <CurrencyCircleDollar size={22} color="#333333" />
                {total?.toLocaleString() || 0}
              </span>
            </h4>
            <button onClick={() => {}}>Checkouts</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Cart;
