import React, { useState, useEffect } from "react";
import { CurrencyCircleDollar, CheckCircle, Bag } from "phosphor-react";
import styles from "./Product.module.scss";

const Product = ({ item, setCartItems, cartItems }) => {
  const [inCart, setInCart] = useState(false);

  useEffect(() => {
    let foundItem = cartItems?.find((cartItem) => cartItem?.id === item?.id);
    if (!foundItem) {
      setInCart(false);
    }
  }, [cartItems]);
  return (
    <div className={styles.product}>
      <div className={styles.product__item}>
        <div className={styles.product__item__tags}>
          <div
            className={styles.product__item__cart}
            onClick={() => {
              if (!inCart) {
                setCartItems([{ ...item, count: 1 }, ...cartItems]);
                setInCart(true);
              }
            }}
          >
            {inCart ? (
              <CheckCircle size={25} weight="fill" color="#87E68B" />
            ) : (
              <Bag size={23} weight="regular" color="#333333" />
            )}
          </div>
          <div className={styles.product__item__tag}>
            {`-${Math.floor(
              ((item?.old_price - item?.price) / item?.old_price) * 100
            )}%` || "-30%"}
          </div>
        </div>
        <div className={styles.product__item__image}>
          <img src={item?.image} alt="product image" />
        </div>
      </div>
      <div className={styles.product__desc}>
        <h4 className={styles.product__name}>{item?.name || "Akrina Shirt"}</h4>
        <h5 className={styles.product__price}>
          <CurrencyCircleDollar size={22} color="#333333" />{" "}
          {item?.price?.toLocaleString() || 0}
          <span className={styles.product__price__previous}>
            {" "}
            <CurrencyCircleDollar size={22} color="#b3b3b3" />
            {item?.old_price?.toLocaleString() || 0}
          </span>
        </h5>
      </div>
    </div>
  );
};

export default Product;
