import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styles from "./ProductDetails.module.scss";
import { CurrencyCircleDollar, CheckCircle, Bag } from "phosphor-react";
import { courses } from "../../utils/courses";

const ProductDetails = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [course, setCourse] = useState({});

  useEffect(() => {
    if (id) {
      const _course = courses?.find((item) => item?.id === Number(id));
      // console.log(_course, id, courses);
      if (!_course) {
        navigate("/");
        return;
      }
      setCourse(_course);
    }
  }, [id]);

  return (
    <div className={styles.productDetails}>
      <div className={styles.productDetails__main}>
        <div className={styles.productDetails__main__heading}>
          <h4>Home / Course Details</h4>
          <h2>Course Details</h2>
        </div>
        <div className={styles.productDetails__main__content}>
          <img src={course?.image} alt="" />
          <div className={styles.productDetails__main__content__details}>
            <h6 className={styles.productDetails__main__content__details__tag}>
              Duration: {course?.duration}
            </h6>
            <h3
              className={styles.productDetails__main__content__details__title}
            >
              {course?.title}
            </h3>

            <h5
              className={styles.productDetails__main__content__details__price}
            >
              <CurrencyCircleDollar size={22} color="#333333" />{" "}
              {course?.price?.toLocaleString() || 0}
              <span
                className={
                  styles.productDetails__main__content__details__price__previous
                }
              >
                {" "}
                <CurrencyCircleDollar size={22} color="#b3b3b3" />
                {course?.old_price?.toLocaleString() || 0}
              </span>
            </h5>
            <p className={styles.productDetails__main__content__details__desc}>
              {course?.longDesc}
            </p>
            <button
              className={styles.productDetails__main__content__details__buy}
            >
              BUY COURSE
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductDetails;
