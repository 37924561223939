import Routes from "./router/Routes";
import { BrowserRouter as Router } from "react-router-dom";
import "./styles/index.scss";

function App() {
  return (
    <div className="">
      <Router>
        <Routes />
      </Router>
    </div>
  );
}

export default App;
