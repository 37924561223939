import IOT from "../images/iot.jpeg";
import AI from "../images/ai.jpeg";
import Salesforce from "../images/salesforce.jpeg";
import Software from "../images/software.jpeg";

export const courses = [
  {
    title: "Data Analytics",
    image: IOT,
    desc: "Become a certified Data Analyst",
    longDesc:
      "Transform Your Data into Actionable Insights: Join Our Comprehensive Course in Data Analytics to Master the Art of Unearthing Meaningful Information, and Empower Yourself to Make Informed Decisions",
    price: 180,
    old_price: 230,
    id: 1,
    duration: "10 weeks",
  },
  {
    title: "Salesforce Administrator",
    image: Salesforce,
    desc: "Become a Salesforce certified Administrator",
    longDesc:
      "Become the Salesforce Expert You Were Born to Be: Elevate Your Career with Our Salesforce Administrator Course and Unlock the Full Potential of this Industry-Leading CRM Platform.",
    price: 180,
    old_price: 230,
    id: 2,
    duration: "14 weeks",
  },
  {
    title: "Software Engineering Intro",
    image: Software,
    desc: "Learn to speak to your computer with fluency",
    longDesc:
      "Craft Your Future in Code: Unleash Your Potential with Our Comprehensive Software Development Course and Forge a Path to Success in the World of Technology and Innovation.",
    price: 230,
    old_price: 290,
    id: 3,
    duration: "12 weeks",
  },
  {
    title: "Basics of AI",
    image: AI,
    desc: "Become a certified AI expert",
    longDesc:
      "Navigate the Frontier of Artificial Intelligence: Embark on a Journey of Discovery with Our Cutting-Edge AI Course and Shape the Future of Innovation with the Power of Machine Learning and Deep Learning.",
    price: 300,
    old_price: 550,
    id: 4,
    duration: "12 weeks",
  },
];
