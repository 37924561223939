import React, { cloneElement, useState, useEffect } from "react";

import Cart from "../components/Cart/Cart";
import Navbar from "../components/Navbar/Navbar";
import Footer from '../components/Footer/Footer'
import styles from "./Layout.module.scss";

const Layout = ({ children, name }) => {
  const [showCart, setShowCart] = useState(false);
  const [cartItems, setCartItems] = useState([]);
  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const [email, setEmail] = useState();
  const [phoneNumber, setPhoneNumber] = useState();
  return (
    <div className={styles.layout}>
      <Navbar
        showCart={showCart}
        setShowCart={setShowCart}
        setCartItems={setCartItems}
        cartItems={cartItems}
        active={name}
      />
      <div className={styles.layout__content}>
        {cloneElement(children, {
          showCart,
          setShowCart,
          cartItems,
          setCartItems,
        })}
      </div>

      {showCart && (
        <Cart
          setShowCart={setShowCart}
          cartItems={cartItems}
          setCartItems={setCartItems}
          // isLoading={isLoading}
          // mutate={mutate}
          firstName={firstName}
          setFirstName={setFirstName}
          lastName={lastName}
          setLastName={setLastName}
          email={email}
          setEmail={setEmail}
          phoneNumber={phoneNumber}
          setPhoneNumber={setPhoneNumber}
        />
      )}
      <Footer setShowCart={setShowCart} />
    </div>
  );
};

export default Layout;
